import { Box, Center, Heading, Text } from '@chakra-ui/react';

import { PageWrapper } from '@components/page_wrapper';

/**
 * 404 view
 */
const NotFound: React.FC = () => {
  return (
    <PageWrapper alignItems={'center'}>
      <Center minH={'50vh'} m={'0 28px'} textAlign={'center'}>
        <Box>
          <Heading as={'h1'}>404 | Not Found</Heading>
          <Text mt={2}>
            The page you were looking for doesn&apos;t seem to exist...
          </Text>
        </Box>
      </Center>
    </PageWrapper>
  );
};

export default NotFound;
